import React, { FC, useEffect, useRef, useState } from "react";

import {
  Button,
  CheckboxOptionType,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import axios, { AxiosResponse } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AddModal from "./AddModal";
import styles from "./ConnectAds.module.scss";
import CreateRK from "./CreateRK";
import EditUtm from "./EditUtm";
import HandleResponse from "./HandleResponse";
import { showErrorMessage } from "../../../../helpers/utils/ui";
import { axiosConfigAuth } from "../../../../shared/common/api/middleware";
import {
  API_ADS_ADD_RK_TOKEN,
  API_ADS_GET_DATA,
  API_REPORT_TABLE,
} from "../../../../shared/common/api/urls";
import { routeNames } from "../../../../shared/common/constants/routes";
import { useActions } from "../../../../shared/common/hooks/useActions";
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector";
export interface IItem {
  id?: string;
  name?: string;
  data?: unknown;
}
export interface IhandleResponseProps {
  visible: boolean;
  message: string;
  type: "success" | "error";
}
import vkImg from "../../../../shared/common/img/VK.png";
import Mask from "../../../../shared/common/components/portals/Mask";
import { ImportFile } from "./ImportFile";
import ManualAddVK from "./ManualAddVK";
import { splitNameAndId } from "../../../../helpers/utils/functions";
import TableReport from "../../../../shared/common/components/reports/table-report/TableReport";
import EditModal from "../../school/staff/EditModal";
import AdButton from "../add-spend/components/AdButton";
import ProcessRKModal from "../add-spend/components/modals/ProcessRKModal";
import TabSelect from "../add-spend/components/TabSelect";
import usePermission from "../../../../shared/common/hooks/usePermission";
import AutoWidthSelect from "../../../../shared/common/components/auto-width-select/AutoWidthSelect";
import { currencies } from "../../../../helpers/utils/data";

import { RedoOutlined } from "@ant-design/icons";

const ConnectAds: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const { cabinetData, isLoading, isUpdate } = useTypedSelector(
    (state) => state.adsCabinet
  );
  const { isTableFetching } = useTypedSelector((state) => state.meta);
  const { filters } = useTypedSelector((state) => state.filter);
  const {
    adsCabinetGetData,
    setAdsCabinetUpdate,
    adsCabinetUpdateRKStatus,
    adsCabinetUpdateRKnds,
    adsCabinetUpdateRKCurrency,
    adsCabinetUpdateRKConvert,
    adsCabinetUpdateUTMStatus,
    adsCabinetUpdateUTMLabel,
    adsCabinetUpdateUTMMask,
    adsCabinetUpdateAdCabinet,
    setMetaTableFetching,
  } = useActions();

  const [subTab, setSubTab] = useState(
    Number(new URLSearchParams(location.search).get("sub")) || 1
  );
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [addManualVisible, setAddManualVisible] = useState(false);
  const [addRKVisible, setAddRKVisible] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isUtmMaskExpanded, setIsUtmMaskExpanded] = useState(false);
  const [importFile, setImportFile] = useState({
    visible: false,
    account_id: 0,
  });
  const [processRKState, setProcessRKState] = useState({
    visible: false,
    id: 0,
  });
  const [utmLabelsState, setUtmLabelsState] = useState({
    visible: false,
    id: 0,
    fields: {},
  });
  const [handleResponseProps, setHandleResponseProps] =
    useState<IhandleResponseProps>({
      visible: false,
      message: "",
      type: "success",
    });
  const [items, setItems] = useState<IItem[]>([{}]);
  const [utmMasks, setUtmMasks] = useState<{
    [key: string]: {
      value: boolean;
      number: number;
      divider: string;
    };
  }>({});

  const [pageMode, setPageMode] = useState(
    Number(new URLSearchParams(location.search).get("tab")) || 1
  );

  const utmMaskIds = useRef<number[]>([]);
  useEffect(() => {
    if (
      window.location.search.length > 0 &&
      window.location.search.length > 6
    ) {
      try {
        setIsLoadingAdd(true);
        const urlParams = new URLSearchParams(window.location.search);
        const formData = new FormData();
        const entries = urlParams.entries();
        for (let entry = entries.next(); !entry.done; entry = entries.next()) {
          const [name, value] = entry.value;
          formData.append(name, value);
        }
        const getAdCabList = (): Promise<AxiosResponse> => {
          const data = { ...Object.fromEntries(formData) };
          return axios.post(API_ADS_ADD_RK_TOKEN, data, axiosConfigAuth());
        };
        getAdCabList()
          .then((response) => {
            if (response.status === 200) {
              if (response.data && Object.keys(response.data).length !== 0) {
                setItems(response.data);
                setIsLoadingAdd(false);
                setAddModalVisible(true);
              }
            }
          })
          .catch((err) => {
            setIsLoadingAdd(false);
            const {
              response: { data: errorMessage },
            } = err;
            if (typeof errorMessage === "object") {
              setHandleResponseProps({
                message: errorMessage.message,
                visible: true,
                type: "error",
              });
            } else {
              setHandleResponseProps({
                message: errorMessage,
                visible: true,
                type: "error",
              });
            }
            console.log("Получение рекламных кабинетов не удалось", err);
          });
      } catch (error) {
        console.log(error);
        setIsLoadingAdd(false);
        showErrorMessage("Получение рекламных кабинетов не удалось");
      } finally {
        navigate(routeNames.adsConnect);
      }
    }
    return () => {
      utmMaskIds.current = [];
    };
  }, []);

  useEffect(() => {
    if (selectedSchool) {
      adsCabinetGetData(parseInt(selectedSchool.id), subTab, filters);
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (selectedSchool) {
      adsCabinetGetData(parseInt(selectedSchool.id), subTab, filters);
    }
  }, [subTab]);

  useEffect(() => {
    if (selectedSchool && (isUpdate || isTableFetching)) {
      setUtmMasks({});
      utmMaskIds.current = [];
      adsCabinetGetData(parseInt(selectedSchool.id), subTab, filters);
      setAdsCabinetUpdate(false);
      setMetaTableFetching({ value: false });
    }
  }, [isUpdate, isTableFetching]);
  const metrics = [
    { name: "Дата рекламного расxода", dataKey: "date_stat" },
    { name: "ID рекламного кабинета", dataKey: "account_id" },
    { name: "Название рекламного кабинета", dataKey: "account_name" },
    { name: "Название кампании", dataKey: "campaign_name" },
    { name: "Название группы объявлений", dataKey: "adset_name" },
    { name: "Название Объявления", dataKey: "ads_name" },
    { name: "ID кампании", dataKey: "campaign_id" },
    { name: "ID группы объявлений", dataKey: "adset_id" },
    { name: "ID объявления", dataKey: "ads_id" },
    { name: "Сумма расхода", dataKey: "spend" },
    { name: "Валюта", dataKey: "currency_name" },
    { name: "utm_source", dataKey: "utm_source_value" },
    { name: "utm_medium", dataKey: "utm_medium_value" },
    { name: "utm_campaign", dataKey: "utm_campaign_value" },
    { name: "utm_term", dataKey: "utm_term_value" },
    { name: "utm_group", dataKey: "utm_group_value" },
    { name: "utm_content", dataKey: "utm_content_value" },
    { name: "Показы", dataKey: "impressions" },
    { name: "Клики", dataKey: "count_link_clicks" },
    { name: "Домен", dataKey: "domain_value" },
    { name: "Страница", dataKey: "page_value" },
    { name: "URL Ссылка", dataKey: "url_value" },
    { name: "Заголовок", dataKey: "title_value" },
    { name: "Подзаголовок", dataKey: "subtitle_value" },
    { name: "Текст", dataKey: "text_value" },
    { name: "ID картинки", dataKey: "picture_value" },
    { name: "URL картинки", dataKey: "picture_url_value" },
    { name: "Превью объявления", dataKey: "preview_url_value" },
    { name: "Текст кнопки", dataKey: "button_text_value" },
    {
      name: "Заголовок рядом с  кнопкой или ссылкой",
      dataKey: "button_title_value",
    },
    { name: "Статус обработки", dataKey: "processing_status" },
    { name: "Комментарий", dataKey: "ads_comment" },
    { name: "Тип цели", dataKey: "goal_type_value" },
    { name: "Формат объявления", dataKey: "ad_format_value" },
    { name: "Способ оплаты", dataKey: "cost_type_value" },
    { name: "Рекламные площадки", dataKey: "ad_platform_value" },
    { name: "URL логотипа объявления", dataKey: "icon_url_value" },
    { name: "Дневной лимит", dataKey: "day_limit" },
    { name: "Общий лимит", dataKey: "all_limit" },
    { name: "Охват подписчиков", dataKey: "reach_subscribers" },
    { name: "Переходы по ссылке", dataKey: "links" },
    { name: "Переходы в сообщество", dataKey: "to_group" },
    { name: "Вступления в сообщество", dataKey: "join_group" },
    { name: "Количество скрытий записи", dataKey: "hide" },
    { name: "Количество жалоб на запись", dataKey: "report" },
    { name: "Количество отписавшихся участников", dataKey: "unsubscribe" },
    {
      name: "Количество стартов просмотра видео",
      dataKey: "video_views_start",
    },
    {
      name: "Количество досмотров видео до 3 секунд",
      dataKey: "video_views_3s",
    },
    {
      name: "Количество досмотров видео до 25 процентов",
      dataKey: "video_views_25p",
    },
    {
      name: "Количество досмотров видео до 50 процентов",
      dataKey: "video_views_50p",
    },
    {
      name: "Количество досмотров видео до 75 процентов",
      dataKey: "video_views_75p",
    },
    {
      name: "Количество досмотров видео до 100 процентов",
      dataKey: "video_views_100p",
    },
  ];

  const handleProcessingRK = (record: { id: number }) => {
    setProcessRKState({ visible: true, id: record.id });
  };

  const utmMasksCellRenderer = ({ cellData, rowData, column }: any) => {
    const { utmMasks } = column;
    if (!rowData.utm_has_id) return;
    console.log(cellData, utmMasks[rowData.id], utmMaskIds.current);
    if (
      cellData !== null &&
      !utmMasks[rowData.id] &&
      !utmMaskIds.current.includes(rowData.id)
    ) {
      utmMaskIds.current = [
        ...utmMaskIds.current.filter((el) => el !== rowData.id),
        rowData.id,
      ];
      setUtmMasks((prev) => ({
        ...prev,
        [rowData.id]: { ...cellData, value: true },
      }));
    }
    return (
      <Space style={{ minWidth: "200px" }}>
        <Select
          style={{ minWidth: "150px" }}
          value={utmMasks[rowData.id]?.value || Boolean(cellData)}
          onChange={(value) => {
            if (selectedSchool) {
              setUtmMasks((prev) => {
                let newObject = { value };
                if (value) {
                  newObject = { ...newObject, ...prev[rowData.id], value };
                }
                adsCabinetUpdateUTMMask(
                  parseInt(selectedSchool.id),
                  rowData.id,
                  newObject
                );
                return { ...prev, [rowData.id]: newObject };
              });
            }
          }}
        >
          <Select.Option value={false}>Один параметр в метке</Select.Option>
          <Select.Option value={true}>
            Несколько параметров в метке
          </Select.Option>
        </Select>
        {utmMasks[rowData.id]?.value && (
          <>
            <Space
              style={{ paddingBottom: "21px", whiteSpace: "nowrap" }}
              direction={"vertical"}
            >
              <label>Какой по счёту?</label>
              <InputNumber
                style={{
                  width: `${Math.max(
                    utmMasks[rowData.id]?.number?.toString()?.length * 10 + 20,
                    50
                  )}px`,
                }}
                value={utmMasks[rowData.id]?.number}
                onChange={(value) => {
                  if (selectedSchool && value) {
                    const stringValue = value.toString();
                    if (stringValue.match(/^\d*$/)) {
                      const numericValue = parseInt(stringValue, 0);
                      setUtmMasks((prev) => {
                        adsCabinetUpdateUTMMask(
                          parseInt(selectedSchool.id),
                          rowData.id,
                          { ...utmMasks[rowData.id], number: numericValue }
                        );
                        return {
                          ...prev,
                          [rowData.id]: {
                            ...prev[rowData.id],
                            number: numericValue,
                          },
                        };
                      });
                    }
                  }
                }}
              />
            </Space>
            <Space style={{ paddingBottom: "21px" }} direction={"vertical"}>
              <label>Разделитель</label>
              <Input
                style={{
                  width: "30px",
                  fontSize: "16px",
                  lineHeight: 0,
                  fontWeight: 600,
                }}
                value={utmMasks[rowData.id]?.divider || ""}
                onChange={(event) => {
                  const newValue = event.target.value;
                  if (/^[^0-9a-zA-Zа-яА-Я]*$/.test(newValue)) {
                    if (selectedSchool) {
                      setUtmMasks((prev) => {
                        adsCabinetUpdateUTMMask(
                          parseInt(selectedSchool.id),
                          rowData.id,
                          { ...utmMasks[rowData.id], divider: newValue }
                        );
                        return {
                          ...prev,
                          [rowData.id]: {
                            ...prev[rowData.id],
                            divider: newValue,
                          },
                        };
                      });
                    }
                  }
                }}
              />
            </Space>
          </>
        )}
      </Space>
    );
  };

  const [columns, setColumns] = useState([
    {
      key: "ad_account",
      title: "Рекламный аккаунт",
      dataKey: "ad_account",
      width: 200,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) => {
        let icon;
        switch (rowData.source_id) {
          case 2:
            icon = (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                style={{ width: 12, marginRight: 6, marginBottom: "-3px" }}
              >
                <path
                  fill="#000"
                  d="M58 45.4C47.8 31 27.8 27.7 13.4 38S-4.3 68.2 6 82.6L160 298.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V298.3L378 82.6c10.3-14.4 6.9-34.4-7.4-44.6S336.2 31 326 45.4L192 232.9 58 45.4z"
                />
              </svg>
            );
            break;
          case 3:
            icon = (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style={{ width: 15, marginRight: 6, marginBottom: "-3px" }}
              >
                <path
                  fill="#000"
                  d="M31.4907 63.4907C0 94.9813 0 145.671 0 247.04V264.96C0 366.329 0 417.019 31.4907 448.509C62.9813 480 113.671 480 215.04 480H232.96C334.329 480 385.019 480 416.509 448.509C448 417.019 448 366.329 448 264.96V247.04C448 145.671 448 94.9813 416.509 63.4907C385.019 32 334.329 32 232.96 32H215.04C113.671 32 62.9813 32 31.4907 63.4907ZM75.6 168.267H126.747C128.427 253.76 166.133 289.973 196 297.44V168.267H244.16V242C273.653 238.827 304.64 205.227 315.093 168.267H363.253C359.313 187.435 351.46 205.583 340.186 221.579C328.913 237.574 314.461 251.071 297.733 261.227C316.41 270.499 332.907 283.63 346.132 299.751C359.357 315.873 369.01 334.618 374.453 354.747H321.44C316.555 337.262 306.614 321.61 292.865 309.754C279.117 297.899 262.173 290.368 244.16 288.107V354.747H238.373C136.267 354.747 78.0267 284.747 75.6 168.267Z"
                />
              </svg>
            );
            break;
          case 4:
            icon = (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style={{ width: 10, marginRight: 6, marginBottom: "-3px" }}
              >
                <path
                  fill="#000"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                />
              </svg>
            );
            break;
          case 5:
            icon = (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 488 512"
                style={{ width: 16, marginRight: 6, marginBottom: "-3px" }}
              >
                <path
                  fill="#000"
                  d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                />
              </svg>
            );
            break;
          case 6:
            icon = (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={{ width: 18, marginRight: 6, marginBottom: "-3px" }}
              >
                <path
                  fill="#000"
                  d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                />
              </svg>
            );
            break;
          default:
            icon = null;
            break;
        }

        return (
          <div style={{ width: "max-content", minWidth: "100px" }}>
            {icon} {cellData}
          </div>
        );
      },
    },
    {
      key: "ad_cabinet",
      title: "Рекламный кабинет",
      dataKey: "ad_cabinet",
      width: 300,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) => (
        <Input
          style={{ minWidth: `${cellData?.length + 1}ch`, height: "unset" }}
          defaultValue={splitNameAndId(cellData)[0]}
          suffix={
            <span style={{ fontWeight: "700" }}>
              {splitNameAndId(cellData)[1]}
            </span>
          }
          onBlur={(event) => {
            if (selectedSchool) {
              adsCabinetUpdateAdCabinet(
                parseInt(selectedSchool.id),
                rowData.id,
                event.target.value
              );
            }
          }}
        />
      ),
    },
    {
      key: "currency_id",
      title: "Валюта рекламного кабинета",
      dataKey: "currency_id",
      width: 200,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) => (
        <AutoWidthSelect
          value={cellData}
          onChange={(value) => {
            if (selectedSchool) {
              adsCabinetUpdateRKCurrency(
                parseInt(selectedSchool.id),
                rowData.id,
                value
              );
            }
          }}
          options={currencies}
        />
      ),
    },
    {
      key: "utm_has_id",
      title: "В UTM метке есть ID объявления?",
      dataKey: "utm_has_id",
      width: 200,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) => {
        return (
          <Select
            style={{ minWidth: "50px" }}
            value={Boolean(cellData)}
            onChange={(value) => {
              if (selectedSchool) {
                adsCabinetUpdateUTMStatus(
                  parseInt(selectedSchool.id),
                  rowData.id,
                  value
                );
              }
            }}
          >
            <Select.Option value={true}>Да</Select.Option>
            <Select.Option value={false}>Нет</Select.Option>
          </Select>
        );
      },
    },
    {
      key: "utm_label",
      title: "В какой UTM метке ID объявления?",
      dataKey: "utm_label",
      width: 200,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) =>
        rowData.utm_has_id && (
          <Select
            style={{ minWidth: "120px" }}
            value={cellData}
            onChange={(value) => {
              if (selectedSchool) {
                adsCabinetUpdateUTMLabel(
                  parseInt(selectedSchool.id),
                  rowData.id,
                  value
                );
              }
            }}
          >
            <Select.Option value="utm_source">utm_source</Select.Option>
            <Select.Option value="utm_medium">utm_medium</Select.Option>
            <Select.Option value="utm_campaign">utm_campaign</Select.Option>
            <Select.Option value="utm_term">utm_term</Select.Option>
            <Select.Option value="utm_content">utm_content</Select.Option>
            <Select.Option value="utm_group">utm_group</Select.Option>
          </Select>
        ),
    },
    {
      key: "utm_mask",
      title: "Где в UTM метке ID объявления?",
      dataKey: "utm_mask",
      width: 250,
      resizable: true,
      utmMasks,
      cellRenderer: utmMasksCellRenderer,
    },
    {
      title: "Основные UTM метки источника",
      dataIndex: "utm_labels",
      key: "utm_labels",
      cellRenderer: ({ cellData, rowData }: any) => {
        const hasUTMLabels =
          rowData.utm_source ||
          rowData.utm_medium ||
          rowData.utm_campaign ||
          rowData.utm_term ||
          rowData.utm_content ||
          rowData.utm_group;

        if (rowData.utm_has_id && hasUTMLabels) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
              }}
              onClick={() =>
                setUtmLabelsState({
                  visible: true,
                  id: rowData.id,
                  fields: {
                    utm_source: rowData.utm_source?.split(","),
                    utm_medium: rowData.utm_medium?.split(","),
                    utm_campaign: rowData.utm_campaign?.split(","),
                    utm_term: rowData.utm_term?.split(","),
                    utm_content: rowData.utm_content?.split(","),
                    utm_group: rowData.utm_group?.split(","),
                  },
                })
              }
            >
              {rowData.utm_source && (
                <div>utm_source = {rowData.utm_source}</div>
              )}
              {rowData.utm_medium && (
                <div>utm_medium = {rowData.utm_medium}</div>
              )}
              {rowData.utm_campaign && (
                <div>utm_campaign = {rowData.utm_campaign}</div>
              )}
              {rowData.utm_term && <div>utm_term = {rowData.utm_term}</div>}
              {rowData.utm_content && (
                <div>utm_content = {rowData.utm_content}</div>
              )}
              {rowData.utm_group && <div>utm_group = {rowData.utm_group}</div>}
            </div>
          );
        } else {
          return (
            <Button
              onClick={() =>
                setUtmLabelsState({
                  visible: true,
                  id: rowData.id,
                  fields: {
                    utm_source: [],
                    utm_medium: [],
                    utm_campaign: [],
                    utm_term: [],
                    utm_content: [],
                    utm_group: [],
                  },
                })
              }
            >
              Добавить UTM метки
            </Button>
          );
        }
      },
      width: 250,
    },
    {
      key: "commission",
      title: "Конвертация в RUB по курсу ЦБ + %",
      dataKey: "commission",
      width: 200,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) => (
        <InputNumber
          value={cellData}
          min={0}
          step={1}
          onChange={(value) => {
            if (selectedSchool && value !== null) {
              adsCabinetUpdateRKConvert(
                parseFloat(selectedSchool.id),
                rowData.id,
                parseFloat(value)
              );
            }
          }}
        />
      ),
    },
    {
      key: "with_vat",
      title: "Учитывать НДС 20%",
      dataKey: "with_vat",
      width: 150,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) =>
        cellData !== null ? (
          <Switch
            checked={cellData}
            onChange={(value) => {
              if (selectedSchool) {
                adsCabinetUpdateRKnds(
                  parseInt(selectedSchool.id),
                  rowData.id,
                  value
                );
              }
            }}
          />
        ) : null,
    },
    {
      key: "status",
      title: "Статус",
      dataKey: "status",
      width: 150,
      resizable: true,
      cellRenderer: ({ cellData, rowData }: any) => (
        <Switch
          checked={cellData}
          onChange={(value) => {
            if (selectedSchool) {
              adsCabinetUpdateRKStatus(
                parseInt(selectedSchool.id),
                rowData.id,
                value
              );
            }
          }}
        />
      ),
    },
    {
      key: "message",
      title: "Ошибки",
      dataKey: "message",
      width: 200,
      resizable: true,
    },
    {
      key: "ads_count_all",
      title: "Всего объявлений",
      dataKey: "ads_count_all",
      width: 200,
      resizable: true,
    },
    {
      key: "ads_count_active",
      title: "Всего активных объявлений",
      dataKey: "ads_count_active",
      width: 200,
      resizable: true,
    },
    {
      key: "ads_count_unprocessed",
      title: "Объявлений в обработке",
      dataKey: "ads_count_unprocessed",
      width: 200,
      resizable: true,
    },
    {
      key: "updated_at",
      title: "Обновлено",
      dataKey: "updated_at",
      width: 150,
      resizable: true,
    },
    {
      title: "Действия",
      key: "cab_actions",
      dataKey: "cab_actions",
      width: 140,
      cellRenderer: ({ rowData }: any) => (
        <Tooltip title="Действия">
          <Space>
            <Button
              type="default"
              size="small"
              icon={<RedoOutlined />}
              onClick={() => handleProcessingRK(rowData)}
            >
              Обработать
            </Button>
          </Space>
        </Tooltip>
      ),
    },
  ]);

  const plainOptions = [
    { label: "Активные", value: 1 },
    { label: "Неактивные", value: 2 },
  ];
  const adsOptions = [
    { label: "Рекламные кабинеты", value: 1 },
    { label: "Объявления", value: 2 },
  ] as (string | number | CheckboxOptionType)[];

  const handleChange = (e: RadioChangeEvent) => {
    const newMode = e.target.value;
    setSubTab(Number(newMode));
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sub", newMode);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    const isUtmMasksExist =
      Object.keys(utmMasks)?.length &&
      Object.values(utmMasks)?.every((el) => el.value);
    setColumns((prevColumns): any => {
      return prevColumns.map((column) => {
        if (column.key === "utm_mask") {
          return {
            ...column,
            width: isUtmMasksExist ? 440 : 209,
            utmMasks,
            cellRenderer: utmMasksCellRenderer,
          };
        }
        return column;
      });
    });
  }, [utmMasks]);

  const adButtonStyles = { width: "220px", height: "105px" };
  const textStyles = { fontWeight: "500" };
  return (
    <div>
      <TabSelect
        options={adsOptions}
        pageMode={pageMode}
        setPageMode={setPageMode}
      />
      {pageMode === 1 && (
        <>
          <HandleResponse {...handleResponseProps} />
          <div className={styles.addSection}>
            <AdButton
              textStyles={textStyles}
              buttonStyles={adButtonStyles}
              imgStyles={{ height: "50px" }}
              imgSrc={"/assets/img/ads/yandex-direct.png"}
              text={"Яндекс.Директ"}
              onClick={() => {
                window.location.href =
                  "https://oauth.yandex.ru/authorize?response_type=code&client_id=9871eb82638e4b7d92daf2523cb69ce2&force_confirm=1";
              }}
            />
            <AdButton
              textStyles={textStyles}
              buttonStyles={adButtonStyles}
              imgStyles={{ height: "50px" }}
              imgSrc={"/assets/img/ads/vk_ads.png"}
              text={"VK Реклама"}
              onClick={() => {
                window.location.href = `https://ads.vk.com/hq/settings/access?action=oauth2&client_id=vwTDP2bl3P0mVpg0&response_type=code&scope=read_ads,read_clients,read_manager_clients&state=${uuidv4()}`;
              }}
            />
            <AdButton
              textStyles={textStyles}
              buttonStyles={adButtonStyles}
              imgStyles={{ height: "50px" }}
              imgSrc={"/assets/img/ads/vk.png"}
              text={
                <>
                  Вконтакте
                  <br />
                  (старый кабинет)
                </>
              }
              onClick={() => {
                window.location.href =
                  "https://oauth.vk.com/authorize?client_id=7692474&redirect_uri=https://lk.scananalytics.ru/source/ads?source=3&scope=groups,offline,ads&response_type=code&revoke=1";
              }}
            />
            <AdButton
              textStyles={textStyles}
              buttonStyles={adButtonStyles}
              imgStyles={{ height: "50px" }}
              imgSrc={"/assets/img/ads/google.png"}
              text={"Google Ads"}
              onClick={() => {
                window.location.href =
                  "https://accounts.google.com/o/oauth2/v2/auth?client_id=551266154922-fm2gmcu5vvdf4tul8a6k5p93o2ti9u6t.apps.googleusercontent.com&redirect_uri=https://lk.scananalytics.ru/source/ads?source=5&response_type=code&scope=https://www.googleapis.com/auth/adwords&access_type=offline";
              }}
            />
            <AdButton
              textStyles={textStyles}
              buttonStyles={adButtonStyles}
              imgStyles={{ height: "50px" }}
              imgSrc={"/assets/img/ads/my-target.png"}
              text={"myTarget"}
              onClick={() => {
                const uid = uuidv4();
                window.location.href = `https://target.my.com/oauth2/authorize?response_type=code&client_id=B6alsRCUAzmVXyEr&state=${uid}&scope=read_ads`;
              }}
            />
            <AdButton
              textStyles={textStyles}
              buttonStyles={adButtonStyles}
              imgStyles={{ height: "50px" }}
              imgSrc={"/assets/img/ads/fb.png"}
              text={"Facebook Ads"}
              onClick={() => {
                window.location.href =
                  "https://help.scananalytics.ru/ruchnaya-zagruzka-dannyh-po-reklame-iz-facebook-ads/";
              }}
            />
          </div>

          <div className={styles.addSection}>
            <Radio.Group
              options={plainOptions}
              onChange={handleChange}
              value={subTab}
              optionType="button"
              buttonStyle="solid"
            />
          </div>

          <TableReport
            customColumns={columns}
            apiUrl={API_ADS_GET_DATA}
            metrics={columns.map((el) => ({ name: el.title, dataKey: el.key }))}
            withFilter={true}
            filtersFilter={["Реклама (АР-РК)"]}
            withSegment={true}
          />
        </>
      )}

      {pageMode === 2 && (
        <TableReport
          apiUrl={API_REPORT_TABLE}
          metrics={metrics}
          withFilter={true}
          filtersFilter={["Реклама (АР)"]}
          withSegment={true}
        />
      )}
      {processRKState.visible && (
        <ProcessRKModal
          state={processRKState}
          setRefetch={() => {
            setMetaTableFetching({ value: true });
          }}
          setState={setProcessRKState}
        />
      )}
      {isLoadingAdd && <Mask isOpen={isLoadingAdd} />}
      {addModalVisible && (
        <AddModal
          isVisible={addModalVisible}
          setVisible={setAddModalVisible}
          items={items}
          setItems={setItems}
        />
      )}
      {addManualVisible && (
        <ManualAddVK
          isVisible={addManualVisible}
          setVisible={setAddManualVisible}
          items={items}
          setItems={setItems}
          setImportFile={setImportFile}
        />
      )}
      {importFile.visible && (
        <ImportFile
          visible={importFile.visible}
          setVisible={setImportFile}
          accountId={importFile.account_id}
        />
      )}
      {addRKVisible && (
        <CreateRK isVisible={addRKVisible} setVisible={setAddRKVisible} />
      )}
      {utmLabelsState.visible && (
        <EditUtm setState={setUtmLabelsState} state={utmLabelsState} />
      )}
    </div>
  );
};

export default ConnectAds;
