import React, { FC, useEffect, useState } from "react"

import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Popover, Tooltip } from "antd"
import { IAttributionItem } from "../../../../../../app/types/filter"
import { useTypedSelector } from "../../../../../../shared/common/hooks/useTypedSelector"
import {useActions} from "../../../../../../shared/common/hooks/useActions";


const ManualSelect: FC<IAttributionItem> = ({ id,
                                                icon,
                                                name,
                                                selectValues,
                                                description,
                                                isDashboardAttributes
                                            }) => {

    const filtersReq = useTypedSelector((state) => isDashboardAttributes ? state.attributes.attributesDashboard :  state.attributes.attributes)

    const { setAttributes, setAllDataFetching,setDashboardAttributes,setAllBlocksFetching } = useActions()

    const [ isPopoverVisible, setIsPopoverVisible ] = useState(false)
    const [ currentSelection, setCurrentSelection ] = useState(selectValues[0])

    useEffect(() => {
        initialCurrentSelection()
    }, [])

    const initialCurrentSelection = () => {
        const initialValue = filtersReq[`${id}`]
        if (initialValue) {
            const _index = String(initialValue)

            activateSelection(selectValues.find((value) => value.id === _index), true)
        } else {
            activateSelection(currentSelection, true)
            const payload = {
                ...filtersReq,
                [`${id}`]: [ `${currentSelection.id}` ]
            }
            if(isDashboardAttributes){
                setAllBlocksFetching({value:true})
                setTimeout(()=>{
                    setAllBlocksFetching({value:false})
                },3000)
            }
            isDashboardAttributes ? setDashboardAttributes(payload) :  setAttributes(payload)
        }
    }

    useEffect(() => {
        if (id && filtersReq && filtersReq[`${id}`]) {
            const currentSel = selectValues.find((item) => item.id === filtersReq[`${id}`][0])

            if (currentSel) {
                setCurrentSelection(currentSel)
            }
        }
    }, [ filtersReq, id ])


    const handleVisibleChange = (newVisible: boolean) => {
        setIsPopoverVisible(newVisible)
    }

    const activateSelection = (value: any, initial = false) => {
        const payload = {
            ...filtersReq,
            [`${id}`]: [ `${value?.id}` ]
        }
        if(isDashboardAttributes){
            setAllBlocksFetching({value:true})
            setTimeout(()=>{
                setAllBlocksFetching({value:false})
            },3000)
        }
        isDashboardAttributes ? setDashboardAttributes(payload) : setAttributes(payload)

        setCurrentSelection(value)

        !initial && setAllDataFetching({value:true})
        setIsPopoverVisible(false)
    }

    const list = () => (
        <div
            className="params__attr-select-list"
        >
            {
                selectValues.length > 0 ?
                    selectValues.map((value: any, index: number) => (
                        <div key={index} className="segment-list-item" onClick={() => activateSelection(value)}>
                            {value.icon ? value.icon : null} {value.name}
                        </div>
                    ))
                    : <div>Нет данных</div>
            }
        </div>
    )

    return (
        <div className="params__attr-list-item">
            <Popover
                content={list}
                open={isPopoverVisible}
                onOpenChange={handleVisibleChange}
                trigger="click"
                placement="bottomLeft"
                showArrow={false}
                overlayStyle={{ paddingTop: 0 }}
            >
                <Button
                    icon={icon ? icon : null}
                    className="params__segment-show-button"
                >
                    {name}&nbsp;
                    {currentSelection.icon ? currentSelection.icon : null} {currentSelection.name ? currentSelection.name : "Не выбрано"}
                    &nbsp; <DownOutlined />
                </Button>
            </Popover>

            {
                description &&
                <span style={{ marginLeft: 6, marginRight: 10, marginTop: 3 }}>
                    <Tooltip
                        placement="bottom"
                        title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: description }}></div>}
                        color="#fff"
                        zIndex={9999}
                        trigger="click"
                        overlayClassName="page-title-tooltip"
                    >
                      <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }}/>
                    </Tooltip>
                </span>
            }
        </div>
    )
}

export default ManualSelect
